import Script from 'next/script';

export function Tracking() {
    return (
        <>
            <Script
                id="linkedin-script"
                strategy="afterInteractive"
                dangerouslySetInnerHTML={{
                    __html: `
                                _linkedin_partner_id = '4707913';
                                window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
                                window._linkedin_data_partner_ids.push(_linkedin_partner_id);
                                (function (l) {
                                if (!l) {
                                    window.lintrk = function (a, b) {
                                    window.lintrk.q.push([a, b]);
                                    };
                                    window.lintrk.q = [];
                                }
                                var s = document.getElementsByTagName('script')[0];
                                var b = document.createElement('script');
                                b.type = 'text/javascript';
                                b.async = true;
                                b.src = 'https://snap.licdn.com/li.lms-analytics/insight.min.js';
                                s.parentNode.insertBefore(b, s);
                                })(window.lintrk);
                            `,
                }}
            />
            <noscript>
                {/* eslint-disable-next-line @next/next/no-img-element */}
                <img
                    height="1"
                    width="1"
                    style={{ display: 'none' }}
                    alt=""
                    src="https://px.ads.linkedin.com/collect/?pid=4707913&fmt=gif"
                />
            </noscript>
            <Script
                id="twitter-script"
                strategy="afterInteractive"
                dangerouslySetInnerHTML={{
                    __html: `
                        !function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);
                        },s.version='1.1',s.queue=[],u=t.createElement(n),u.async=!0,u.src='https://static.ads-twitter.com/uwt.js',
                        a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,'script');
                        twq('config','oc3dl');
                    `,
                }}
            />
        </>
    );
}
